<template>
    <div id="account-confirmation-component">
        <v-card-title class="justify-center text-center">
            <v-img :src="$store.getters['display_option/logo']" alt="Logo" class="mb-4" contain max-height="70" width="100%" />

            <strong v-if="!loading_verify_account">
                {{
                    page_account_activate
                        ? $t('account_confirmation_component.account_confirm')
                        : $t('account_confirmation_component.thanks_registration')
                }}
            </strong>
        </v-card-title>

        <v-card-text class="pb-0 text-center">
            <p v-if="!loading_verify_account" class="text-center">
                {{
                    page_account_activate
                        ? $t('account_confirmation_component.account_confirmed')
                        : $t('account_confirmation_component.email_send_inscription')
                }}
            </p>

            <v-progress-circular v-else color="primary" indeterminate size="50" />
        </v-card-text>

        <v-card-actions>
            <v-btn
                v-if="page_account_activate && !loading_verify_account"
                block
                color="primary secondary--text font-weight-bold"
                large
                @click="$emit('showLoginComponent')"
            >
                {{ $t('login_account_component.login') }}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import SignUpLinkRepository from '@/repositories/SignUpLinkRepository'

export default {
    name: 'AccountConfirmationComponent',
    data() {
        return {
            loading_verify_account: false,
        }
    },
    props: {
        sign_up_link: {
            type: String,
        },
        page_account_activate: {
            type: Boolean,
            required: true,
        },
    },
    created() {
        if (this.page_account_activate) {
            this.checkIfSignUpLinkExistByToken()
        }
    },
    methods: {
        checkIfSignUpLinkExistByToken() {
            this.loading_verify_account = true

            SignUpLinkRepository.getCheckIfSignUpLinkExistByToken(this.sign_up_link)
                .finally(() => {
                    this.loading_verify_account = false
                })
                .catch((error) => {
                    this.manageError(error)
                    this.$emit('showLoginComponent')
                })
        },
    },
}
</script>
