<template>
    <div id="password-reset-component">
        <v-card-title class="justify-center text-center">
            <v-img :src="$store.getters['display_option/logo']" alt="Logo" class="mb-4" contain max-height="70" width="100%" />

            <strong class="line-height-22">
                {{
                    email_reset_send
                        ? $t('reset_password_component.email_reset_send')
                        : $t('reset_password_component.forget_password_title')
                }}
            </strong>
        </v-card-title>

        <v-card-text class="pb-0 text-center">
            <p>
                {{
                    email_reset_send
                        ? $t('reset_password_component.instruction_send_mail_reset')
                        : $t('reset_password_component.instruction_reset_password')
                }}
            </p>

            <v-text-field
                v-if="!email_reset_send"
                v-model="email"
                :label="$t('reset_password_component.email')"
                :rules="email_rules"
                class="pt-2"
                filled
                hide-details="auto"
                @keypress.enter="sendEmailReset()"
            />
        </v-card-text>

        <div v-if="!email_reset_send" class="pl-4 pr-4 pt-6">
            <v-btn
                :disabled="!email_structure.test(email)"
                block
                class="primary secondary--text font-weight-bold"
                large
                @click="sendEmailReset()"
            >
                <v-progress-circular v-if="loading_sending_mail" class="mr-2" color="secondary" indeterminate size="20" width="2" />
                <span class="secondary--text font-weight-bold">{{ $t('reset_password_component.send') }}</span>
            </v-btn>
            <v-btn block class="font-weight-bold mt-2" large text @click="$emit('showPasswordReset')">
                {{ $t('global.return') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import UserRepository from '@/repositories/UserRepository'

export default {
    name: 'PasswordResetComponent',
    data: function () {
        return {
            email: '',
            email_structure: /^\S+@\S+\.\S+$/,
            email_rules: [
                (v) => !!v || this.$t('reset_password_component.email_required'),
                (v) => this.email_structure.test(v) || this.$t('reset_password_component.email_not_valid'),
            ],
            email_reset_send: false,
            loading_sending_mail: false,
        }
    },
    methods: {
        sendEmailReset() {
            if (this.email_structure.test(this.email)) {
                this.loading_sending_mail = true

                UserRepository.postSendEmailResetPassword({
                    email: this.email,
                })
                    .then(() => {
                        this.email_reset_send = true
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        this.loading_sending_mail = false
                    })
            }
        },
    },
}
</script>
