import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'

const resource = 'api/v1/sign_up_link'

export default {
    getCheckIfSignUpLinkExistByToken(token: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.get(`${resource}?token=${token}`)
    },
}
