<template>
    <div
        id="login-page"
        :style="`background: url(${
            is_mobile && $store.getters['display_option/background_login_mobile']
                ? $store.getters['display_option/background_login_mobile']
                : $store.getters['display_option/background_login']
        }) no-repeat center fixed;`"
        class="h-100 bg-size-cover"
    >
        <v-row align="center" class="h-100 ma-0" justify="center">
            <v-col class="col-mobile-login display-flex-content-center h-100 pa-0" cols="12" lg="3" md="4">
                <v-card
                    :class="`${is_mobile ? 'max-width-300' : 'h-100 rounded-0'} border-none pa-2 d-flex flex-column justify-center`"
                    elevation="1"
                    outlined
                >
                    <login-account-component
                        v-if="!show_password_reset && show_login_component"
                        v-on:showAccountConfirmation="showAccountConfirmation"
                        v-on:showPasswordReset="show_password_reset = !show_password_reset"
                    />

                    <account-confirmation-component
                        v-if="show_account_confirmation"
                        :page_account_activate="page_account_activate"
                        :sign_up_link="sign_up_link"
                        v-on:showLoginComponent="showLoginComponent"
                    />

                    <reset-password-component
                        v-if="show_password_reset"
                        v-on:showPasswordReset="show_password_reset = !show_password_reset"
                    />

                    <new-password-component
                        v-if="!show_login_component && !show_account_confirmation"
                        :code="code"
                        :uid="uid"
                        v-on:showLoginComponent="showLoginComponent"
                    />
                </v-card>
            </v-col>

            <v-col v-if="!is_mobile" class="pa-0" lg="9" md="8" />
        </v-row>

        <p v-if="!is_mobile" class="display-version version-login-desktop">{{ $t('parameter_page.version') }} {{ app_version }}</p>
    </div>
</template>

<script>
import LoginAccountComponent from '@/components/Login/LoginAccountComponent'
import ResetPasswordComponent from '@/components/Login/ResetPasswordComponent'
import NewPasswordComponent from '@/components/Login/NewPasswordComponent'
import AccountConfirmationComponent from '@/components/Login/AccountConfirmationComponent'

export default {
    name: 'LoginPage',
    data: function () {
        return {
            show_password_reset: false,
            show_login_component: true,
            show_account_confirmation: false,
            uid: '',
            code: '',
            sign_up_link: '',
            page_account_activate: false,
        }
    },
    components: {
        AccountConfirmationComponent,
        NewPasswordComponent,
        LoginAccountComponent,
        ResetPasswordComponent,
    },
    methods: {
        showLoginComponent() {
            this.page_account_activate = false
            this.show_account_confirmation = false
            this.$router.replace({ query: null })
            this.show_login_component = true
        },

        showAccountConfirmation() {
            this.show_login_component = false
            this.show_account_confirmation = true
        },

        loginAsUserAdmin(token) {
            this.$auth
                .login({
                    url: '/api/v1/token_user_client/check',
                    method: 'GET',
                    params: {
                        token: token,
                    },
                    staySignedIn: true,
                })
                .then(() => {
                    this.$auth.fetch({}).then((result) => {
                        this.setAllInformationAfterConnect(this.$auth.user().id_professional, result.data.data)
                    })
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },
    },
    created() {
        this.uid = this.$route.query.uid
        this.code = this.$route.query.code
        this.sign_up_link = this.$route.query.sign_up_link

        if (this.code !== undefined || this.uid !== undefined) {
            this.show_login_component = false
        } else if (this.sign_up_link !== undefined) {
            this.show_login_component = false
            this.show_account_confirmation = true
            this.page_account_activate = true
        }

        if (this.$route.query.token) {
            this.$store.dispatch('general/setGlobalLoader', true)
            this.loginAsUserAdmin(this.$route.query.token)
        } else if (this.$auth.check()) {
            this.$router.push('/equipment')
        } else {
            document.getElementById('favicon').href = this.$store.getters['display_option/display_option'].src_favicon
        }
    },
}
</script>

<style lang="scss">
.border-login-choose {
    border-bottom: var(--v-primary-base) 3px solid;
    font-weight: bold;
    color: black;
}

.img-background-fullscreen {
    height: 100vh;
}

.version-login-desktop {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
</style>